body {
  background: #00070d;
}

.logo {
  font-size: 1.4rem;
  line-height: 2rem;
}

.trait {
  cursor: pointer;
}
